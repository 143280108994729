import React, { useState } from "react";
import { Typography, Input, Textarea, Checkbox, Button } from "@material-tailwind/react";
import emailjs from 'emailjs-com';

export function FeedbackForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      email: formData.email,
      message: formData.message,
    };

    emailjs.send('service_ydy0pkk', 'template_ebraacu', templateParams, 'Ev0DUHwYBvACiCA31')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
      }, (err) => {
        console.log('FAILED...', err);
      });
  };

  return (
    <section className="grid h-screen place-items-center">
      <div className="mx-auto p-10 text-center lg:max-w-2xl">
        <Typography
          variant="h1"
          color="blue-gray"
          className="mb-4 text-3xl lg:text-5xl"
        >
          Retour Portfolio
        </Typography>
        <Typography variant="lead" className="mb-16 !text-gray-700">
          Pour toute question ou suggestion concernant mon portfolio, veuillez écrire votre message ci-dessous. Merci de votre retour !
        </Typography>

        {submitted ? (
          <Typography className="text-green-500">Merci pour votre message ! Je vous répondrons dès que possible.</Typography>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <Textarea
              rows={6}
              color="gray"
              size="lg"
              label="Votre Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            <Button type="submit" size="lg" color="gray" className="mt-6" fullWidth>
              Envoyer le message
            </Button>
          </form>
        )}
      </div>
    </section>
  );
}

export default FeedbackForm;
