import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import cld from '../cloudinaryConfig';
import { AdvancedVideo } from "@cloudinary/react";

export function CardProject({ videoSrc, title, description, buttonText, buttonLink }) {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleLoadedData = () => {
    setIsLoading(false);
    console.log("Video loaded:", videoSrc);
  };

  useEffect(() => {
    console.log("Video source:", videoSrc);
  }, [videoSrc]);

  return (
    <div className="p-1">
      <Card
        className="w-full max-w-[24rem] mx-auto cursor-pointer"
        onClick={() => window.open(buttonLink, "_blank")}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardHeader 
          color="gray" 
          className={`relative h-56 overflow-hidden transition-transform duration-500 ${isHovered ? 'scale-125' : 'scale-100'}`}
        >
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
              <div className="w-8 h-8 border-4 border-t-4 border-gray-200 rounded-full animate-spin"></div>
            </div>
          )}
          <AdvancedVideo
            ref={videoRef}
            cldVid={cld.video(videoSrc).quality('auto')}
            className="h-full w-full object-cover"
            muted
            playsInline
            onLoadedData={handleLoadedData}
            controls
          />
        </CardHeader>
        <CardBody>
          <Typography variant="h4" color="blue-gray" className="mt-1 mb-2 font-medium">
            {title}
          </Typography>
          <Typography color="gray" className="mb-4 font-normal">
            {description}
          </Typography>
          <Button color="gray" variant="outlined" size="sm" onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            window.open(buttonLink, "_blank");
          }}>
            {buttonText}
          </Button>
        </CardBody>
      </Card>
    </div>
  );
}

export default CardProject;
