import React, { useState } from "react";
import CardProject from "./CardProject";
import NewSection from "./NewSection";
import imgMoi from "../asset/img/moiV2.jpg";

export function Project() {
  const [playingVideo, setPlayingVideo] = useState(null);

  return (
    <div id="project" className="p-4 mt-[10vh]" data-scroll data-scroll-call="project" data-scroll-repeat>
      <NewSection image={imgMoi} title="Mes Projets" />
      <div className="grid gap-y-24 gap-x-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-[4vw] pt-[25vh]">
        <CardProject
          videoSrc="video/stbd65jvnoppn9rqyr5g"
          title="Bois Pyrénées Services"
          description="Création d'un site pour une entreprise de vente de bois de chauffage. Technologies utilisées: HTML, CSS et JavaScript pour une structure robuste et des interactions dynamiques."
          buttonText="Git Hub"
          buttonLink="https://github.com/CalmettesT/BPS"
          setPlayingVideo={setPlayingVideo}
          playingVideo={playingVideo}
        />
        <CardProject
          videoSrc="video/zxqr8xweg62egojaxjap"
          title="Educatrice Canine"
          description="Développement d'un site pour une éducatrice canine à Toulouse. Réalisé avec React et Tailwind CSS pour une interface utilisateur moderne et responsive. En cours de developpement"
          buttonText="Visitez le site"
          buttonLink="https://chlo-educ-canin.fr/"
          setPlayingVideo={setPlayingVideo}
          playingVideo={playingVideo}
        />
        <CardProject
          videoSrc="video/rbixmjvmz999m5d8gskl"
          title="GestInvest"
          description="Développement d'une application mobile pour la gestion des investissements personnels. Technologies utilisées: React Native pour le développement mobile multiplateforme."
          buttonText="Git Hub"
          buttonLink="https://github.com/CalmettesT/reactNative"
          setPlayingVideo={setPlayingVideo}
          playingVideo={playingVideo}
        />
      </div>
    </div>
  );
}

export default Project;
